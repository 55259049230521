var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"custom-card",attrs:{"border-variant":"light"}},[_c('b-form',{staticClass:"alter-form",attrs:{"id":"alter-form"}},[_c('b-form-row',[_c('b-col',[_vm._v(" NOME DO TREINAMENTO ")]),_c('b-col',[_c('b-form-input',{ref:"name",attrs:{"state":_vm.validateField('name'),"placeholder":"Informe o nome do treinamento"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}})],1)],1),_c('b-form-row',[_c('b-col',[_vm._v(" DATA INÍCIO ")]),_c('b-col',[_c('date-picker',{key:_vm.getKeyDate(_vm.form.dateStarted),attrs:{"datePickerId":"dateStarted","dataValue":_vm.form.dateStarted,"state":_vm.validateFieldStartDate('dateStarted', this.allowPastDate())},on:{"datePickerChangedValue":_vm.onChangedDateStarted}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.validateFieldStartDate('dateStarted', this.allowPastDate()) ==
              false
          ),expression:"\n            validateFieldStartDate('dateStarted', this.allowPastDate()) ==\n              false\n          "}],staticClass:"invalid text-invalid-right"},[_vm._v(" "+_vm._s(_vm.startDateErrorMsg)+" ")])],1),_c('b-form-row',[_c('b-col',[_vm._v(" DATA FIM ")]),_c('b-col',[_c('date-picker',{key:_vm.getKeyDate(_vm.form.dateFinished),attrs:{"datePickerId":"dateFinished","state":_vm.validateFieldEndDate('dateFinished', 'dateStarted', false),"dataValue":_vm.form.dateFinished},on:{"datePickerChangedValue":_vm.onChangedDateFinished}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.validateFieldEndDate('dateFinished', 'dateStarted', false) ==
              false
          ),expression:"\n            validateFieldEndDate('dateFinished', 'dateStarted', false) ==\n              false\n          "}],staticClass:"invalid text-invalid-right"},[_vm._v(" "+_vm._s(_vm.endDateErrorMsg)+" ")])],1),(_vm.form.id)?_c('b-form-row',[_c('b-col',[_vm._v(" EXIBIR O TREINAMENTO PARA OS COOPERADOS? ")]),_c('b-col',{staticClass:"custom-align"},[_c('b-form-checkbox',{staticClass:"ml-auto align-right-all-time",attrs:{"switch":""},on:{"change":this.onChangedShowTraining},model:{value:(_vm.form.showTraining),callback:function ($$v) {_vm.$set(_vm.form, "showTraining", $$v)},expression:"form.showTraining"}},[_vm._v(" "+_vm._s(_vm.form.showTraining ? "Sim" : "Não")+" ")])],1)],1):_vm._e(),_c('b-form-row',[_c('b-col',[_vm._v(" DISPONIBILIZAR PARA TODOS OS COOPERADOS ")]),_c('b-col',{staticClass:"custom-align"},[_c('b-form-checkbox',{staticClass:"ml-auto align-right-all-time",attrs:{"switch":""},on:{"change":this.onChangedAllRecipient},model:{value:(_vm.form.allRecipient),callback:function ($$v) {_vm.$set(_vm.form, "allRecipient", $$v)},expression:"form.allRecipient"}},[_vm._v(" "+_vm._s(_vm.form.allRecipient ? "Sim" : "Não")+" ")])],1)],1),(!_vm.form.allRecipient)?_c('b-form-row',[_c('b-col',[_vm._v(" CRM(S) ")]),_c('b-col',{staticClass:"custom-align"},[_c('b-form-tags',{attrs:{"input-id":"tags-basic","placeholder":"Selecione o(s) CRM(s)"},model:{value:(_vm.form.crms),callback:function ($$v) {_vm.$set(_vm.form, "crms", $$v)},expression:"form.crms"}})],1)],1):_vm._e(),(!_vm.form.allRecipient)?_c('b-form-row',[_c('b-col',[_vm._v(" ESPECIALIDADE(S) ")]),_c('b-col',{staticClass:"custom-align"},[_c('b-form-tags',{attrs:{"input-id":"tags-pills","add-on-change":"","no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var tags = ref.tags;
              var inputAttrs = ref.inputAttrs;
              var inputHandlers = ref.inputHandlers;
              var disabled = ref.disabled;
              var removeTag = ref.removeTag;
return [(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-2"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"title":tag,"disabled":disabled,"variant":"info"},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(_vm._s(tag))])],1)}),0):_vm._e(),_c('b-form-select',_vm._g(_vm._b({attrs:{"disabled":_vm.specialtiesOptions.length === 0,"options":_vm.specialtiesOptions},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Selecione a(s) especialidade(s)")])]},proxy:true}],null,true)},'b-form-select',inputAttrs,false),inputHandlers))]}}],null,false,527918809),model:{value:(_vm.form.specialties),callback:function ($$v) {_vm.$set(_vm.form, "specialties", $$v)},expression:"form.specialties"}})],1)],1):_vm._e()],1)],1),_c('b-row',{staticClass:"my-5"},[_c('b-button',{staticClass:"ml-4",attrs:{"variant":"outline-primary","disabled":this.loading},on:{"click":this.onCancel}},[_vm._v(" Cancelar ")]),_c('b-button',{staticClass:"ml-4",attrs:{"form":"alter-form","type":"button","variant":"primary","id":"submitButtonText","disabled":this.loading},on:{"click":function () { return this$1.onSubmitHandler(false); }}},[_vm._v(" Salvar e adicionar informações da trilha ")]),_c('success-modal',{ref:"success-modal",attrs:{"description":_vm.form.id
          ? 'Treinamento editado com sucesso.'
          : 'Treinamento cadastrado com sucesso.',"buttonText":"Ok, entendi!"}})],1),_c('attention-modal',{ref:"confirm-cancel-modal",attrs:{"modalId":"confirm-cancel-modal","title":"Atenção!","submitButtonText":"Sim, salvar primeiro","cancelButtonText":"Prosseguir sem salvar","onAction":this.onActionConfirmCancel}},[_c('template',{slot:"description"},[_c('p',{staticClass:"p1 mb-5 confirm-text"},[_vm._v(" Todas as alterações realizadas serão perdidas com esta ação. Deseja salvar as alterações antes de prosseguir? ")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }