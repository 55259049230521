<template>
  <div>
    <b-card border-variant="light" class="custom-card">
      <b-form id="alter-form" class="alter-form">
        <b-form-row>
          <b-col>
            NOME DO TREINAMENTO
          </b-col>
          <b-col>
            <b-form-input
              ref="name"
              v-model.lazy.trim="form.name"
              :state="validateField('name')"
              placeholder="Informe o nome do treinamento"
            />
          </b-col>
        </b-form-row>
        <b-form-row >
          <b-col>
            DATA INÍCIO
          </b-col>
          <b-col>
            <date-picker 
              datePickerId="dateStarted"
              :key="getKeyDate(form.dateStarted)"
              :dataValue="form.dateStarted"
              :state="
                validateFieldStartDate('dateStarted', this.allowPastDate())
              "
              @datePickerChangedValue="onChangedDateStarted"
            />
          </b-col>
          <div
            class="invalid text-invalid-right"
            v-show="
              validateFieldStartDate('dateStarted', this.allowPastDate()) ==
                false
            "
          >
            {{ startDateErrorMsg }}
          </div>
        </b-form-row>
        <b-form-row>
          <b-col>
            DATA FIM
          </b-col>
          <b-col>
            <date-picker
              datePickerId="dateFinished"
              :key="getKeyDate(form.dateFinished)"
              :state="
                validateFieldEndDate('dateFinished', 'dateStarted', false)
              "
              :dataValue="form.dateFinished"
              @datePickerChangedValue="onChangedDateFinished"
            />
          </b-col>
          <div
            class="invalid text-invalid-right"
            v-show="
              validateFieldEndDate('dateFinished', 'dateStarted', false) ==
                false
            "
          >
            {{ endDateErrorMsg }}
          </div>
        </b-form-row>
        <b-form-row v-if="form.id">
          <b-col>
            EXIBIR O TREINAMENTO PARA OS COOPERADOS?
          </b-col>
          <b-col class="custom-align">
            <b-form-checkbox
              class="ml-auto align-right-all-time"
              switch
              v-model="form.showTraining"
              @change="this.onChangedShowTraining"
            >
              {{ form.showTraining ? "Sim" : "Não" }}
            </b-form-checkbox>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            DISPONIBILIZAR PARA TODOS OS COOPERADOS
          </b-col>
          <b-col class="custom-align">
            <b-form-checkbox
              class="ml-auto align-right-all-time"
              switch
              v-model="form.allRecipient"
              @change="this.onChangedAllRecipient"
            >
              {{ form.allRecipient ? "Sim" : "Não" }}
            </b-form-checkbox>
          </b-col>
        </b-form-row>
        <b-form-row v-if="!form.allRecipient">
          <b-col>
            CRM(S)
          </b-col>
          <b-col class="custom-align">
            <b-form-tags
              input-id="tags-basic"
              v-model="form.crms"
              placeholder="Selecione o(s) CRM(s)"
            ></b-form-tags>
          </b-col>
        </b-form-row>
        <b-form-row v-if="!form.allRecipient">
          <b-col>
            ESPECIALIDADE(S)
          </b-col>
          <b-col class="custom-align">
            <b-form-tags
              input-id="tags-pills"
              v-model="form.specialties"
              add-on-change
              no-outer-focus
            >
              <template
                v-slot="{
                  tags,
                  inputAttrs,
                  inputHandlers,
                  disabled,
                  removeTag,
                }"
              >
                <ul
                  v-if="tags.length > 0"
                  class="list-inline d-inline-block mb-2"
                >
                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag
                      @remove="removeTag(tag)"
                      :title="tag"
                      :disabled="disabled"
                      variant="info"
                      >{{ tag }}</b-form-tag
                    >
                  </li>
                </ul>
                <b-form-select
                  v-bind="inputAttrs"
                  v-on="inputHandlers"
                  :disabled="specialtiesOptions.length === 0"
                  :options="specialtiesOptions"
                >
                  <template #first>
                    <!-- This is required to prevent bugs with Safari -->
                    <option disabled value=""
                      >Selecione a(s) especialidade(s)</option
                    >
                  </template>
                </b-form-select>
              </template>
            </b-form-tags>
          </b-col>
        </b-form-row>
      </b-form>
    </b-card>
    <b-row class="my-5">
      <b-button
        variant="outline-primary"
        :disabled="this.loading"
        @click="this.onCancel"
        class="ml-4"
      >
        Cancelar
      </b-button>

      <b-button
        form="alter-form"
        type="button"
        class="ml-4"
        variant="primary"
        id="submitButtonText"
        :disabled="this.loading"
        @click="() => this.onSubmitHandler(false)"
      >
        Salvar e adicionar informações da trilha
      </b-button>
      <success-modal
        ref="success-modal"
        :description="
          form.id
            ? 'Treinamento editado com sucesso.'
            : 'Treinamento cadastrado com sucesso.'
        "
        buttonText="Ok, entendi!"
      >
      </success-modal>
    </b-row>
    <attention-modal
      modalId="confirm-cancel-modal"
      title="Atenção!"
      submitButtonText="Sim, salvar primeiro"
      cancelButtonText="Prosseguir sem salvar"
      ref="confirm-cancel-modal"
      :onAction="this.onActionConfirmCancel"
    >
      <template slot="description">
        <p class="p1 mb-5 confirm-text">
          Todas as alterações realizadas serão perdidas com esta ação. Deseja
          salvar as alterações antes de prosseguir?
        </p>
      </template>
    </attention-modal>
  </div>
</template>

<script>
import DatePicker from "../../../../components/date-picker/DatePicker.vue";
import {
  validateCheckBox,
  validateField,
  requiredFieldsFilled,
  validateFieldStartDate,
  validateFieldEndDate,
} from "../../../../utils/validate/validate.js";
import RoutesPaths from "../../../../router/router-structure/routes-paths";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import { createToast } from "../../../../components/toast/toast";
import AttentionModal from "../../../../components/base-modals/AttentionModal.vue";
import { inject } from "@vue/composition-api";

import {
  getTrainingById,
  saveTraining,
  getSpecialties,
} from "../../../../services/training/trainingManagementService";

const DEFAULT_FORM = {
  name: "",
  dateStarted: null,
  dateFinished: null,
  allRecipient: false,
  showTraining: null,
  crms: [],
  specialties: [],
  endDateErrorMsg: "",
  startDateErrorMsg: "",
};
export default {
  name: "general-information-training",
  props: ["onChangeTab", "index"],
  components: { DatePicker, SuccessModal, AttentionModal },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  data() {
    return {
      formSubmited: false,
      dateStartedKey: null,
      dateFinishedKey: null,
      validations: {},
      loading: false,
      endDateErrorMsg: "",
      startDateErrorMsg: "",
      specialtiesOptions: [],
      originalValues: DEFAULT_FORM,
      form: DEFAULT_FORM,
      validateField,
      validateCheckBox,
      requiredFieldsFilled,
      validateFieldStartDate,
      validateFieldEndDate,
    };
  },
  async mounted() {
    this.globalLoading = true;
    try {
      this.specialtiesOptions = this.availableOptions(await getSpecialties());
      if (this.$route.params.id) {
        const training = await getTrainingById(this.$route.params.id);
        this.form = { ...training };
        this.form.dateStarted =
          training.dateStarted != null && training.dateStarted != undefined
            ? new Date(training.dateStarted)
            : null;
        this.form.dateFinished =
          training.dateFinished != null && training.dateFinished != undefined
            ? new Date(training.dateFinished)
            : null;
        this.form.allRecipient = training.recipient === "ALL";
        this.form.showTraining =
          training.trainingStatus == "REGISTER_FINISHED_AND_VISIBLE";
        this.originalValues = { ...this.form };
      } else {
        this.form = { ...this.originalValues };
      }
    } catch (error) {
      console.error("mounted", error);
      createToast("Atenção", "Ocorreu um erro carregar as informações.");
    }

    this.globalLoading = false;
  },
  methods: {
    onActionConfirmCancel(confirmed) {
      if (!confirmed) {
        this.$router.push(RoutesPaths.trainingManagement.rootName());
        return;
      }
      this.onSubmitHandler(true);
    },
    allowPastDate() {
      if (!this.form.id) return true;
      return false;
    },
    availableOptions(specialties) {
      return [
        ...specialties
          .filter((opt) => this.form.specialties.indexOf(opt) === -1)
          .map((sp) => ({
            value: sp.description,
            text: sp.description,
          })),
      ];
    },
    onChangedShowTraining() {
      this.form = { showTraining: !this.form.showTraining, ...this.form };
    },
    onChangedAllRecipient() {
      this.form = { allRecipient: !this.form.allRecipient, ...this.form };
    },
    getKeyDate(date) {
      return date?.toString();
    },
    onCancel() {
      if (
        this.originalValues.allRecipient == this.form.allRecipient &&
        this.originalValues.showTraining == this.form.showTraining &&
        this.originalValues.name == this.form.name &&
        this.originalValues.dateStarted == this.form.dateStarted &&
        this.originalValues.dateFinished == this.form.dateFinished &&
        this.originalValues.crms == this.form.crms &&
        this.originalValues.specialties == this.form.specialties
      ) {
        this.$router.push(RoutesPaths.trainingManagement.rootName());
        return;
      }

      const modal = this.$refs["confirm-cancel-modal"].$children[0];
      modal.show();
    },
    onChangedDateFinished(value) {
      this.form.dateFinished = value;
    },
    onChangedDateStarted(value) {
      this.form.dateStarted = value;
    },
    async onSubmitHandler(cancelButton) {
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        this.loading = true;
        try {
          const trainingStatus = this.form.showTraining
            ? "REGISTER_FINISHED_AND_VISIBLE"
            : "REGISTER_FINISHED_AND_INVISIBLE";

          const trainingSaved = await saveTraining({
            allRecipient: this.form.allRecipient,
            ...this.form,
            trainingStatus,
          });

          createToast(
            "Informação",
            `Treinamento ${
              this.form.id ? "editado" : "cadastrado"
            } com sucesso.`,
            "success",
            2000
          );
          setTimeout(async () => {
            this.loading = false;
            if (cancelButton) {
              this.$router.push(RoutesPaths.trainingManagement.rootName());
            } else {
              if (!this.form.id) {
                await this.$router.push({
                  path: RoutesPaths.trainingManagement.form(
                    trainingSaved.idTraining
                  ),
                });
              }
              this.$props.onChangeTab(this.$props.index + 1);
            }
          }, 1500);
        } catch (error) {
          if (error.response && error.response.status == 400) {
            const msg = `Não é possível ${
              this.form.id ? "alterar" : "salvar"
            } o treinamento. Motivo: ${error.response.data.message}`;
            createToast("Informação", msg, "warning");
          } else {
            console.error("onSubmit", error);
            createToast(
              "Atenção",
              "Ocorreu um erro ao salvar informações do treinamento."
            );
          }

          this.loading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.b-form-tags-button {
  display: none;
}
</style>
<style lang="scss" scoped>
.align-right-all-time {
  position: absolute;
  right: 4px;
}
.b-form-tags-input {
  font-size: 1.2rem;
}
.b-form-tag-content {
  font-size: 1.1rem;
}
.b-form-tag > button.b-form-tag-remove {
  font-size: 180%;
}
.text-invalid-right {
  width: 100%;
  text-align: right;
}
.custom-align {
  margin-right: 1rem;
}
</style>
